@tailwind base;
@tailwind components;
@tailwind utilities;

@import "assets/font/IbmPlexSans/stylesheet.css";

body {
  margin: 0;
  font-family: "IBM Plex Sans Condensed" !important;
}
html {
  scroll-behavior: smooth;
}
code {
  /* font-family: "ibmPlexSans"; */
}
