@tailwind base;
@tailwind components;
@tailwind utilities;

/* header */
.header-test {
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  /* background-color: #ffffff; */
  -moz-transition: background-color 1.5s;
  -ms-transition: background-color 1.5s;
  -o-transition: background-color 1.5s;
}

/* Knowledge */

/* OurTeam */
/* width */
::-webkit-scrollbar {
  width: 10px;
  padding-right: 10px !important;
  margin-right: 10px !important;
}

/* button */
::-webkit-scrollbar-button {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #27c6fe;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #000;
}

/* The track NOT covered by the handle.
    /* ::-webkit-scrollbar-track-piece {
        background: #000;
    } */
/* Corner */
::-webkit-scrollbar-corner {
  background: #999;
}

/* Resizer */
::-webkit-resizer {
  background: #111;
}

::-webkit-scrollbar-track {
  display: none;
}
.card-slide-zoom {
  transition: transform 300ms ease-in-out;
}
@media (min-width: 600px) {
  .card-slide-zoom:hover {
    transform: scale(1.1) translateZ(10px);
  }
}

.btn-linebar-effect {
  @apply relative overflow-hidden before:content-[""] before:absolute before:w-0 before:h-1 before:bg-gradient-button before:transition-[width]
     before:duration-[0.3s] before:ease-[ease-out] before:left-0 before:bottom-0 group-hover:before:w-full;
}

.scroll-container::-webkit-scrollbar {
  width: 10px;
  padding-right: 10px !important;
  margin-right: 10px !important;
}

/* button */
.scroll-container::-webkit-scrollbar-button {
  display: none;
}

/* Handle */
.scroll-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #daebf1;
}

/* Track */
.scroll-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #000;
}

.scroll-container::-webkit-scrollbar-corner {
  background: #999;
}

.scrollable-container {
  overflow-y: auto;
  height: 300px; /* Chiều cao tùy chọn */
  scroll-behavior: smooth; /* Thêm hiệu ứng cuộn mượt mà */
}

.btn-Telegram .bg-shape {
  left: -500px;
  transition: all 0.5s ease-in-out;
}
.btn-Telegram:hover .bg-shape {
  left: 0;
}

.btn-Telegram {
  line-height: 0;
}
.btn-Telegram .btn-content {
  transition: all 0.5s ease-in-out;
}

@layer utilities {
  .custom-background {
    background: radial-gradient(
        68.42% 175% at 96.59% -90.62%,
        rgba(225, 27, 84, 0.86) 54.93%,
        rgba(255, 193, 7, 0.38) 100%
      ),
      radial-gradient(
        75% 351.94% at -10.23% 35.94%,
        rgba(192, 0, 0, 0.92) 21.25%,
        rgba(225, 27, 84, 0.42) 100%
      ),
      radial-gradient(
        39.2% 181% at 5.68% 100%,
        rgba(246, 251, 34, 0.51) 0%,
        rgba(255, 158, 69, 0) 100%
      ),
      radial-gradient(
        31.25% 236.33% at 96.59% 31.25%,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0) 100%
      );
  }
}

.background_banner {
  background: url("../../assets/background/bg_home.png") bottom no-repeat;
  /* background-size: cover; */
  overflow: hidden;
}

@layer utilities {
  .text-gradient-border {
    color: transparent;
    -webkit-text-stroke: 2px transparent;
    background-clip: text;
    -webkit-background-clip: text;
    background: linear-gradient(90deg, #4ade80, #3b82f6);
  }
}

.text-shadow-black {
  text-shadow: 2px 2px 6px rgba(110, 110, 110, 0.514);
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.animate-gradient {
  background: linear-gradient(45deg, #ff8a00, #da1b60, #ff8a00, #da1b60);
  background-size: 400% 400%;
  animation: gradientAnimation 3s ease infinite;
}
